import {AgreementModel} from "../../../Apis/Models/AgreementModel";
import {ReactElement, useEffect, useState} from "react";
import React from 'react';
import Modal from "../../../Components/V2/Modal";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import InputLabel from "../../../Components/V2/InputLabel";
import {ControlledInput} from "../../../Components/V2/Input";
import FlexRow from "Components/FlexRow";
import Grid from "Components/V2/Grid";
import Button from "Components/V2/Button";
import {useUpdateAgreement} from "../../../Apis/AgreementApi";
import useToast from "../../../Hooks/useToast";

type Props = {
    agreement: AgreementModel;
    trigger: ReactElement;
}
const CompanyDetailsModal = ({ agreement, trigger}: Props) => {

    const { t } = useTranslation();

    const [visible, setVisible] = useState<boolean>(false);

    const { reset, control, handleSubmit } = useForm<AgreementModel>();

    const { mutateAsync: updateAgreement, isSuccess, isLoading } = useUpdateAgreement()

    const { showSuccessToast } = useToast();

    useEffect(() => {
        if (visible) {
            reset(agreement)
        } else {
            reset({})
        }
    }, [reset, visible, agreement]);

    const onSubmit = async (data: AgreementModel) => {
        await updateAgreement({...agreement, ...data})

        showSuccessToast(t('onboarding.companyDetailsUpdated'))

        setTimeout(() => {
            setVisible(false)
        }, 2000)
    }

    return (
        <>
            {React.cloneElement(trigger, {onClick: () => setVisible(true)})}

            <Modal visible={visible} title={t('onboarding.editCompanyDetails')} onCancel={() => setVisible(false)}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <FlexRow justify={"center"}>
                        <Grid gridTemplateColumns={"400px"} rowGap={16}>
                            <div>
                                <InputLabel required>
                                    {t('invoiceEmail')}
                                </InputLabel>
                                <ControlledInput name={'invoiceEmail'} placeholder={t('yourInvoiceEmail')} control={control}
                                                 rules={{required: true}}/>
                            </div>

                            <div>
                                <InputLabel required>
                                    {t('vatNumber')}
                                </InputLabel>
                                <ControlledInput name={'vatNumber'} placeholder={t('companyVatNumber')} control={control}
                                                 rules={{required: true}}/>
                            </div>

                            <div>
                                <InputLabel>
                                    {t('companyName')}
                                </InputLabel>
                                <ControlledInput name={'companyName'} placeholder={t('companyName')} control={control} rules={{required: true}}/>
                            </div>

                            <FlexRow justify={"end"}>
                                <Button text={t('confirm')} state={isLoading ? 'Loading' : isSuccess ? 'Success' : 'None'} type={"submit"} />
                            </FlexRow>
                        </Grid>
                    </FlexRow>
                </form>
            </Modal>
        </>
    )

}

export default CompanyDetailsModal